import React, { Component } from 'react';

import noImage from '../../../assets/noimage.png';

import { Header } from 'semantic-ui-react';

import styles from './HubButton.scss';

class HubButton extends Component {
  state = {
    image: null
  }

  componentDidMount() {
    if (this.props.item) {
      this.changeIcon(this.props.item.appIconUrl ? this.props.item.appIconUrl : noImage);
    }
  }

  changeIcon = (image) => this.setState({ image });

  render() {
    return (
      <div>
        <a className={styles.HubButton}
          href={this.props.item.appUrl}
          onMouseOver={() => this.changeIcon(this.props.item.appIconHoverUrl ? this.props.item.appIconHoverUrl : noImage)}
          onMouseOut={() => this.changeIcon(this.props.item.appIconUrl ? this.props.item.appIconUrl : noImage)}>
          <div className={styles.imgContainer}
            style={{ backgroundImage: 'url(' + this.state.image + ')' }} />
          <Header as='h1'>{this.props.item.clientName}</Header>
          <Header as='h4'>{this.props.item.description}</Header>
        </a>
      </div>
    )
  }
}

export default HubButton;