import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';

import * as serviceWorker from './serviceWorker';

import { RpmOidc, initUserManager } from 'rug-widgets';

import 'semantic-ui-css/semantic.min.css';

import { history, configureStore } from './shared/store/configureStore';

import App from './App';
import * as constants from './shared/constants/constants';

import './index.scss';

initUserManager(constants.AUTH_CONFIG);

const store = configureStore();

const rootElement = document.getElementById('root');
ReactDOM.render(
  <Provider store={store}>
    <RpmOidc store={store} history={history}
      redirectPath='/callback'
      callbackPageContent={<div>Redirecting...</div>}>
      <App />
    </RpmOidc>
  </Provider>,
  rootElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
