import React, { Component } from 'react';

import Navbar from './modules/Navbar/Navbar';
import Hub from './modules/Hub/Hub';

import styles from './App.scss';

class App extends Component {
  render() {
    return <div className={styles.App}>
      <Navbar />
      <Hub />
    </div>
  }
}

export default App;
