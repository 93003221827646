import React, { Component } from 'react';
import { connect } from 'react-redux';

import axios from '../../axios/axios-management-api';

import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';

import { shallowEqual } from '../../shared/utility';

import * as actions from '../../shared/store/actions';

import { Dimmer, Loader } from 'semantic-ui-react';

import HubButton from './HubButton/HubButton';

import styles from './Hub.scss';

class Hub extends Component {
  state = {
    applications: [],
    image: null
  }

  componentDidMount() {
    if (this.props.user) {
      this.props.fetchApplications();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.user && this.props.user !== nextProps.user) {
      this.props.fetchApplications();
    }

    if (nextProps.applications && this.props.applications !== nextProps.applications) {
      this.setState({ applications: nextProps.applications });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!shallowEqual(prevState.currentEnvinronment, this.state.currentEnvinronment))
      this.reloadData(this.state.currentEnvinronment);
  }

  reloadData = (currentEnvinronment) => {
    this.props.fetchApplications(currentEnvinronment);
  }

  handleChange = (e, { value }) => this.setState({ currentEnvinronment: value })

  render() {
    return (
      <div className={styles.HubContainer}>
        {this.props.loading
          ?
          <Dimmer active inverted className={styles.Dimmer}>
            <Loader inverted content='Loading' size='huge' className={styles.Loader} />
          </Dimmer>
          : null}
        <div className={styles.Grid}>
          {this.state.applications && this.state.applications.map(item => <HubButton key={item.id} item={item} />)}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.hub.loading,
    applications: state.hub.applications,
    user: state.oidc.user,
    isLoadingUser: state.oidc.isLoadingUser
  };
}

const mapDispatchToProps = dispatch => {
  return {
    fetchApplications: () => dispatch(actions.fetchApplications())
  };
}

export default withErrorHandler(connect(mapStateToProps, mapDispatchToProps)(Hub), axios);