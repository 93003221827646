export const MANAGEMENT_API_URL = (() => {
  if (process.env.REACT_APP_ENV === 'dev') {
    // redirect proxy is used
    return 'https://authmanagement.loadrpm.com/api/';
  }

  if (process.env.REACT_APP_ENV === 'prod') {
    return 'https://authmanagement.loadrpm.com/api/';
  }
})();

const AUTH_CLIENT_ID = (() => {
  if (process.env.REACT_APP_ENV === 'dev') {
    return 'Rug';
  }

  if (process.env.REACT_APP_ENV === 'prod') {
    return 'Rug';
  }
})();

export const AUTH_CONFIG = {
  client_id: AUTH_CLIENT_ID,
  redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/callback`,
  post_logout_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`,
  response_type: 'token id_token',
  scope: 'openid profile',
  authority: 'https://auth.loadrpm.com',
  silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/silent_renew.html`,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true
};