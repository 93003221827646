import React from 'react';

import { Message } from 'semantic-ui-react';

import styles from './Snackbar.scss'

const snackbar = (props) => {
  let snackbar = <></>;

  if (props.visible) {
    snackbar = <div className={styles.Snackbar}>
      <Message
        color={props.error ? 'red' : 'green'}
        className={styles.Message}
        header={props.header}
        content={props.message}
        onDismiss={props.handleDismiss} />
    </div>
  }

  return snackbar;
}

export default snackbar;