import * as actionTypes from '../../../shared/store/actions/actionTypes';

import axios from '../../../axios/axios-management-api';

export const fetchApplicationsStart = () => {
  return {
    type: actionTypes.FETCH_APPLICATIONS_START
  };
};

export const fetchApplicationsSuccess = (applications) => {
  return {
    type: actionTypes.FETCH_APPLICATIONS_SUCCESS,
    applications: applications
  };
};

export const fetchApplicationsFail = () => {
  return {
    type: actionTypes.FETCH_APPLICATIONS_FAIL
  };
};

export const fetchApplications = () => {
  return dispatch => {
    dispatch(fetchApplicationsStart());
    axios.get('userspellbar/get-applications')
      .then(res => {
        dispatch(fetchApplicationsSuccess(res.data));
      })
      .catch(err => {
        dispatch(fetchApplicationsFail());
      });
  };
};