import React, { Component } from 'react';
import { connect } from 'react-redux';

import Snackbar from '../../shared/UI/Snackbar/Snackbar';

const withErrorHandler = (WrappedComponent, axios) => {
  class Wrapper extends Component {
    state = {
      errorMessage: null,
      error: false,
      withErrorHandlerInitialized: false
    }

    componentWillUpdate(nextProps, nextState) {
      if (nextState.error && nextState.error === true) {
        setTimeout(() => {
          this.setState({ error: false });
        }, 10000);
      }
    }

    componentWillMount() {
      if (this.props.user) {
        if (this.resInterceptor) {
          axios.interceptors.response.eject(this.resInterceptor);
        }

        this.reqInterceptor = axios.interceptors.request.use(req => {
          req.headers.Authorization = `Bearer ${this.props.user.access_token}`;

          this.setState({ error: null });
          return req;
        });
      }

      this.resInterceptor = axios.interceptors.response.use(res => res, error => {
        if (error.response && error.response.status) {
          switch (+error.response.status) {
            case 401:
              this.setState({ errorMessage: 'Not Authorized', error: true });
              break;
            case 404:
              this.setState({ errorMessage: 'Not Found', error: true });
              break;
            case 504:
              this.setState({ errorMessage: 'Internal Server Error', error: true });
              break;
            case 500:
              this.setState({ errorMessage: error.response.data.message ? error.response.data.message : 'Something went wrong', error: true });
              break;
            default:
              this.setState({ errorMessage: 'Something went wrong', error: true });
              break;
          }
        } else {
          this.setState({ errorMessage: 'Something went wrong', error: true });
        }

        throw error;
      });
    }

    componentWillReceiveProps(nextProps, nextState) {
      if (nextProps.user && nextProps.user !== this.props.user) {
        this.reqInterceptor = axios.interceptors.request.use(req => {
          req.headers.Authorization = `Bearer ${nextProps.user.access_token}`;

          this.setState({ error: null });
          return req;
        });
      }
    }

    componentWillUnmount() {
      axios.interceptors.request.eject(this.reqInterceptor);
      axios.interceptors.response.eject(this.resInterceptor);
    }

    errorConfirmedHandler = () => {
      this.setState({ error: false, errorMessage: null });
    }

    handleDismiss = () => {
      this.setState({ error: false, errorMessage: null });
    }

    render() {
      let snackbar =
        <>
          <WrappedComponent {...this.props} withErrorHandlerInitialized={this.state.withErrorHandlerInitialized} />
          <Snackbar
            header='Error'
            error={true}
            visible={Boolean(this.state.error)}
            handleDismiss={this.handleDismiss}
            message={this.state.errorMessage ? this.state.errorMessage : null}>
          </Snackbar>
        </>;

      return snackbar;
    }
  }

  const mapStateToProps = state => {
    return {
      user: state.oidc.user,
      isLoadingUser: state.oidc.isLoadingUser
    }
  }

  return connect(mapStateToProps, null)(Wrapper);

  // return Wrapper;
}

export default withErrorHandler;