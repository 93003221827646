import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userManager } from 'rug-widgets';

import { Icon, Dropdown } from 'semantic-ui-react';

import styles from './Navbar.scss';

class Navbar extends Component {

  handleLogout = () => {
    userManager.signoutRedirect()
      .catch(function (error) {
        console.error('error while signing out user', error);
      });
  }

  render() {
    return (
      <div className={styles.Navbar}>
        <Icon name='user circle' className={styles.icon} />
        {this.props.user && (
          <Dropdown item className={styles.userDropDown} text={this.props.user ? this.props.user.profile.name : ''}>
            <Dropdown.Menu>
              <Dropdown.Item onClick={this.handleLogout}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.oidc.user,
    isLoadingUser: state.oidc.isLoadingUser
  };
}

export default connect(mapStateToProps, null)(Navbar);