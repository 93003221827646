import * as actionTypes from '../../../shared/store/actions/actionTypes';

import { updateObject } from '../../../shared/utility';

const initialState = {
  loading: false,
  applications: null
};

const fetchApplicationsStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const fetchApplicationsSuccess = (state, action) => {
  const updatedState = updateObject(state, {
    loading: false,
    applications: action.applications
  });

  return updatedState;
};

const fetchApplicationsFail = (state, action) => {
  return updateObject(state, { loading: false });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_APPLICATIONS_START: return fetchApplicationsStart(state, action);
    case actionTypes.FETCH_APPLICATIONS_SUCCESS: return fetchApplicationsSuccess(state, action);
    case actionTypes.FETCH_APPLICATIONS_FAIL: return fetchApplicationsFail(state, action);

    default: return state;
  }
};

export default reducer;