export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties
  };
}

const is = (x, y) => {
  if (x === y) {
      return x !== 0 || 1 / x === 1 / y;
  }
}

export const shallowEqual = (objA, objB) => {
  let hasOwnProperty = Object.prototype.hasOwnProperty;

  if (is(objA, objB)) {
      return true;
  }

  if (typeof objA !== 'object' || objA === null || typeof objB !== 'object' || objB === null) {
      return false;
  }

  let keysA = Object.keys(objA);
  let keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) {
      return false;
  }

  for (let i = 0; i < keysA.length; i++) {
      if (!hasOwnProperty.call(objB, keysA[i]) || !is(objA[keysA[i]], objB[keysA[i]])) {
          return false;
      }
  }

  return true;
}